@import "https://fonts.googleapis.com/css?family=Open+Sans|Raleway:400,500,600,700,800,900";
:root {
  --color-accent: #50d7de;
  --color-tranparent: #00000080;
}

html, body {
  vertical-align: baseline;
  color: #646464;
  height: 100%;
  font-family: Raleway, sans-serif;
  font-size: 14px;
  line-height: 24px;
}

a:focus {
  outline: 0 solid;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 15px;
}

h2, h3, h4 {
  margin: 0 0 25px;
  font-family: Raleway, sans-serif;
}

a {
  text-decoration: none;
  transition: all .35s ease-out;
}

a:hover {
  color: var(--color-accent);
  text-decoration: none;
  transition: all .35s ease-out;
}

a:active, a:hover {
  outline: 0;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.acurate {
  margin: 0;
  padding: 0;
}

.mobile-menu-area {
  display: none;
}

.padding-top {
  padding-top: 30px;
}

.padding-top1 {
  padding-top: 60px;
}

.section-area {
  color: #fff;
  text-align: center;
  background-image: linear-gradient(90deg, #073aff -20%, #0ff 75%);
  border-radius: 30px;
  width: 20rem;
  margin-bottom: 30px;
  padding: .5rem;
  font-size: 30px;
  font-weight: 600;
  display: inline-block;
}

.header-area {
  background: var(--color-tranparent);
  z-index: 9999;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
}

.header-area .logo-area {
  text-align: center;
  color: #fff;
  margin-top: 20px;
  padding: 15px 0 10px;
  font-size: 3.5rem;
  font-weight: bold;
}

.header-area img {
  width: 5rem;
  margin-top: 15px;
}

.header-area .logo-area span {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(#686868, #eae2e2);
  -webkit-background-clip: text;
  font-size: 1.5rem;
  display: inline-block;
}

.header-area .main-menu ul {
  text-align: right;
}

.header-area .main-menu ul li {
  padding: 35px 25px;
  transition: all .3s;
  display: inline-block;
  position: relative;
}

.header-area .main-menu ul li:hover ul {
  opacity: 1;
  visibility: visible;
  top: 100%;
  transform: scaleY(1);
}

.header-area .main-menu ul li ul {
  transform-origin: 0 0 0;
  opacity: 0;
  visibility: hidden;
  z-index: 9;
  background: #fff;
  width: 200px;
  transition: all .3s;
  position: absolute;
  top: 100%;
  left: 0;
  transform: scaleY(0);
}

.header-area .main-menu ul li ul li {
  text-align: left;
  border-bottom: 1px solid var(--color-accent);
  padding: 7px 10px;
  transition: all .3s;
  display: block;
}

.header-area .main-menu ul li ul li:last-child {
  border-bottom: 0;
}

.header-area .main-menu ul li ul li a {
  color: #777;
  text-transform: inherit;
  font-size: 14px;
  font-weight: normal;
}

.header-area .main-menu ul li ul li a:after {
  display: none;
}

.header-area .main-menu ul li ul li a:hover {
  color: var(--color-accent);
}

.header-area .main-menu ul li.active a {
  color: var(--color-accent);
  position: relative;
}

.header-area .main-menu ul li.active a:after {
  content: "";
  background: var(--color-accent);
  width: 25px;
  height: 2px;
  margin: auto;
  font-style: normal;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.header-area .main-menu ul li a {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  transition: all .35s ease-out;
  display: block;
  position: relative;
}

.header-area .main-menu ul li a:after {
  content: "";
  background: var(--color-accent);
  width: 0;
  height: 2px;
  margin: auto;
  font-style: normal;
  transition: all .35s ease-out;
  position: absolute;
  top: 30px;
  left: 0;
  right: 0;
}

.header-area .main-menu ul li a:hover {
  color: var(--color-accent);
  position: relative;
}

.header-area .main-menu ul li a:hover:after {
  width: 25px;
}

.header-area .menu-right ul {
  text-align: center;
}

.header-area .menu-right ul li {
  width: 48px;
  display: inline-block;
  position: relative;
}

.header-area .menu-right ul li:last-child:after {
  display: none;
}

.header-area .menu-right ul li:after {
  content: "";
  background: #fff;
  width: 1px;
  height: 40px;
  margin: auto;
  font-style: normal;
  transition: all .35s ease-out;
  position: absolute;
  top: 30px;
  right: 0;
}

.header-area .menu-right ul li span {
  z-index: 999;
  position: absolute;
  bottom: -24px;
  right: 26px;
}

.header-area .menu-right ul li i {
  color: #fff;
  cursor: pointer;
  text-transform: uppercase;
  padding: 37px 0;
  font-size: 20px;
  text-decoration: none;
  transition: all .35s ease-out;
  display: inline-block;
}

.header-area .menu-right ul li i:hover {
  color: var(--color-accent) !important;
}

.mean-container .mean-nav {
  position: absolute;
  top: 50px;
}

.slide-menu-area {
  background: var(--color-accent);
  opacity: 0;
  visibility: hidden;
  width: 0;
  height: 100vh;
  padding: 130px 30px;
  transition: all .5s;
  position: fixed;
  top: 0;
  right: -360px;
}

.slide-menu-area .close {
  color: #fff;
  opacity: 1;
  padding: 10px;
  position: absolute;
  top: 0;
  left: 0;
}

.slide-menu-area .close i {
  color: #fff;
  cursor: pointer;
  font-size: 20px;
  transition: all .35s ease-out;
}

.slide-menu-area .close i:hover {
  color: #222;
}

.slide-menu-area.highlight {
  opacity: 1;
  visibility: visible;
  z-index: 99999;
  width: 300px;
  right: 0;
}

.slide-menu-area h3 {
  color: #fff;
  font-weight: 600;
  position: relative;
}

.slide-menu-area h3:after {
  content: "";
  background: #fff;
  width: 60px;
  height: 2px;
  font-style: normal;
  position: absolute;
  top: 30px;
  left: 0;
}

.slide-menu-area ul {
  text-align: left;
  transition: all .35s ease-out;
}

.slide-menu-area ul li {
  color: #fff;
  margin-right: 30px;
  padding: 10px 0;
  font-size: 14px;
  display: block;
  position: relative;
}

.slide-menu-area ul li:last-child {
  margin-right: 0;
}

.slide-menu-area ul li span {
  color: #fff;
  padding: 0 1px;
  font-weight: 600;
}

.slide-menu-area ul li span i {
  margin-left: 50px;
}

.slide-menu-area ul li a {
  color: #fff;
  transition: all .35s ease-out;
  display: block;
}

.slide-menu-area ul li a:hover {
  color: #ddd;
}

.slide-menu-area ul li i {
  color: #fff;
  margin-right: 10px;
  font-size: 12px;
}

.home-about-area {
  background: url("about-bg-66c751fb61544.fad82166.webp") center / cover no-repeat;
  padding: 50px 0;
}

.home-about-area h2 span {
  color: var(--color-accent);
  font-size: 30px;
  font-weight: 600;
}

.home-about-area .about-content .logo {
  width: 20rem;
}

.home-about-area .about-content .about-content-list {
  margin-top: 50px;
}

.home-about-area .about-content .about-content-list .single-list .media .pull-left {
  margin: 20px;
}

.home-about-area .about-content .about-content-list .single-list .media .pull-left span {
  color: #fff;
  background: var(--color-accent);
  text-align: center;
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  transition: all .35s ease-out;
  display: block;
  position: relative;
  transform: rotate(45deg);
}

.home-about-area .about-content .about-content-list .single-list .media .pull-left span:hover {
  transform: rotateY(360deg);
}

.home-about-area .about-content .about-content-list .single-list .media .pull-left span i {
  transform: rotate(120deg);
}

.home-about-area .about-content .about-content-list .single-list .media .media-body h3.media-heading {
  color: #646363;
  padding: 10px 0;
  font-size: 20px;
  font-weight: 500;
  transition: all .35s ease-out;
  display: block;
}

.home-about-area .about-content .about-content-list .single-list .media .media-body h3:hover {
  color: var(--color-accent);
}

.video-container {
  width: 100%;
  max-width: 800px;
  margin: 5rem auto auto;
  position: relative;
  overflow: hidden;
}

.video-container video {
  width: 100%;
  height: auto;
}

#Container .mix {
  display: none;
}

.our-work-three {
  padding: 30px 0 0;
  overflow: hidden;
}

.our-work-three .portfolio-page {
  padding: 100px 0 !important;
}

.our-work-three .work-column-menu {
  margin-bottom: 30px;
}

.our-work-three .work-column-menu ul {
  text-align: center;
}

.our-work-three .work-column-menu ul li {
  border: 1px solid var(--color-accent);
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 5px;
  padding: 5px 20px;
  transition: all .35s ease-out;
  display: inline-block;
}

.our-work-three .work-column-menu ul li.active, .our-work-three .work-column-menu ul li:hover {
  background: var(--color-accent);
  color: #fff;
}

.our-work-three .home1-recent-work {
  padding: 20px 0;
}

.our-work-three .home1-recent-work .single-recent-work {
  margin-bottom: 0;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images {
  position: relative;
  overflow: hidden;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images img {
  object-fit: cover;
  width: 100%;
  height: 17rem;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images:hover .work-details {
  opacity: 1;
  transform: scale(1);
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images:hover .link {
  opacity: 1;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details {
  background: var(--color-tranparent) none repeat scroll 0 0;
  width: 100%;
  height: 100%;
  transition: all .5s;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(0);
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title {
  text-align: center;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title h3 {
  color: #fff;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  transition: all .35s ease-out;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title h3 a:hover {
  color: #ddd;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title ul {
  text-align: center;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title ul li {
  color: #fff;
  margin: 0 5px;
  font-size: 14px;
  font-weight: 600;
  transition: all .35s ease-out;
  display: block;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title ul li a:hover {
  color: #ddd;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .link {
  opacity: 0;
  z-index: 999;
  margin: auto;
  padding: 30px;
  transition: all .35s ease-out;
  position: absolute;
  bottom: 0;
  right: 0;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .link a {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
  transition: all .35s ease-out;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .link a:hover {
  color: var(--color-accent);
}

.footer-top {
  color: #828282;
  background: url("about-bg_converted.ae2686bb.webp") center / cover no-repeat;
  padding: 50px 0;
}

.footer-top .widget .footer-logo {
  margin-bottom: 30px;
}

.footer-top .widget .footer-logo a {
  display: block;
}

.footer-top .widget .social-media ul {
  text-align: left;
}

.footer-top .widget .social-media ul li {
  margin: 2px;
  display: inline-block;
}

.footer-top .widget .social-media ul li a {
  color: #ddd;
  border: 1px solid var(--color-accent);
  background: #000;
  width: 30px;
  height: 30px;
  line-height: 28px;
  text-decoration: none;
  transition: all .35s ease-out;
  display: block;
  text-align: center !important;
}

.footer-top .widget .social-media ul li a:hover {
  background: var(--color-accent);
  border: 1px solid var(--color-accent);
  border-radius: 50%;
}

.footer-top .widget.single-footer1 p {
  color: #828282;
}

.footer-top .widget.single-footer2 ul li {
  margin-left: 20px;
  position: relative;
}

.footer-top .widget.single-footer2 ul li:after {
  content: "";
  color: var(--color-accent);
  font-family: fontawesome;
  font-style: normal;
  position: absolute;
  top: 0;
  left: -25px;
}

.footer-top .widget.single-footer2 ul li a {
  color: #828282;
  margin: 0 0 10px;
  font-weight: 500;
  transition: all .35s ease-out;
  display: block;
}

.footer-top .widget.single-footer2 ul li a:hover {
  color: var(--color-accent);
}

.footer-top .widget.single-footer4 .form-control {
  color: #ededed;
  background: none;
  border-radius: 0;
  height: 45px;
}

.footer-top .widget.single-footer4 .btn-send {
  background: var(--color-accent);
  color: #fff;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  padding: 8px 40px;
  transition: all .3s;
}

.footer-top .widget.single-footer4 .btn-send:hover {
  background: var(--color-accent);
}

.footer-top .widget .widget-title {
  color: #fff;
  margin-bottom: 60px;
  font-size: 24px;
  font-weight: 700;
  position: relative;
}

.footer-top .widget .widget-title:after {
  content: "";
  background: var(--color-accent);
  width: 60px;
  height: 2px;
  font-style: normal;
  transition: all .35s ease-out;
  position: absolute;
  top: 50px;
  left: 0;
}

.footer-top .widget .single-post {
  color: #828282;
}

.footer-bottom {
  background: #292929;
  padding: 15px 0;
}

.footer-bottom .footer-copy-right p {
  color: #969696;
  margin: 0;
}

.footer-bottom .footer-copy-right p a {
  color: #fff;
  margin: 0 5px;
  font-weight: 700;
  transition: all .3s;
}

.footer-bottom .footer-copy-right p a:hover {
  color: var(--color-accent);
}

.page-header-area {
  background: url("hk-science-park-16-9.d08de4c5.webp") center / cover no-repeat;
  padding: 8rem 0;
}

.page-header-area .title-container {
  height: fit-content;
  display: inline-block;
  position: relative;
  top: 3rem;
}

.page-header-area .title1 h1 {
  text-align: center;
  color: #fff;
  background-color: var(--color-tranparent);
  background-image: linear-gradient(90deg, #073aff -20%, #50d7de 75%);
  border-radius: 30px;
  width: 30rem;
  padding: 1rem 1.5rem;
  font-size: 1.5rem;
  font-weight: bold;
}

.page-header-area .botton ul li {
  display: inline-block;
}

.page-header-area .botton ul li.active a {
  color: #fff;
  border: 1px solid var(--color-tranparent);
  text-transform: uppercase;
  background: #33ced6d4;
  padding: 10px 20px;
  font-weight: 700;
  transition: all .35s ease-out;
  display: block;
}

.page-header-area .botton ul li a {
  color: #fff;
  background-color: var(--color-tranparent);
  border: 1px solid var(--color-accent);
  text-transform: uppercase;
  padding: 10px 20px;
  font-weight: 700;
  transition: all .35s ease-out;
  display: block;
}

.page-header-area .botton ul li a:hover {
  background: var(--color-accent);
}

.page-header-area .botton ul li.active a:hover {
  background: var(--color-tranparent);
}

.page-header-area ul li:last-child {
  margin-top: 2px;
}

.services-page {
  background: #f8f8f8;
  padding: 50px 0;
}

.services-page .single-service {
  background: #fff;
  height: 30rem;
  padding: 30px;
}

.services-page .single-service .service-image {
  margin-bottom: 30px;
  transition: all .35s ease-out;
}

.services-page .single-service .service-image img {
  object-fit: cover;
  width: 100%;
  height: 220px;
}

.services-page .single-service .service-image img:hover {
  opacity: .9;
}

.services-page .single-service h3 {
  margin-bottom: 10px;
}

.services-page .single-service h3 a {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  transition: all .35s ease-out;
}

.services-page .single-service h3 a:hover {
  color: var(--color-accent);
}

.services-page .single-service p {
  font-size: 1.2rem;
}

.services-page .single-service .read-more {
  position: relative;
}

.services-page .single-service .read-more a {
  text-align: center;
  background: var(--color-accent);
  width: 50px;
  height: 50px;
  line-height: 50px;
  transition: all .35s ease-out;
  position: absolute;
  bottom: -30px;
  right: -30px;
}

.services-page .single-service .read-more a:hover {
  background: var(--color-accent);
}

.services-page .single-service .read-more a i {
  color: #fff;
}

.pagination-area {
  padding-top: 60px;
}

.pagination-area ul {
  text-align: left;
}

.pagination-area ul li {
  display: inline-block;
}

.pagination-area ul li.active a {
  background: var(--color-accent);
  color: #fff;
}

.pagination-area ul li a {
  border: 1px solid var(--color-accent);
  color: #fff;
  text-align: center;
  background: #555;
  width: 50px;
  height: 50px;
  font-size: 20px;
  line-height: 45px;
  text-decoration: none;
  transition: all .35s ease-out;
  display: block;
}

.pagination-area ul li a:hover {
  background: var(--color-accent);
  color: #fff;
}

.related-team-member {
  border-top: 1px solid #ddd;
  padding: 50px 20px;
}

.related-team-member .related-team .single-team {
  margin: 0 15px;
  position: relative;
}

.related-team-member .related-team .single-team:hover .overlay {
  transform: scale(1);
}

.related-team-member .related-team .single-team .team-image {
  height: 400px;
  padding: 0 20px;
  position: relative;
}

.related-team-member .related-team .single-team .team-image:hover .overley {
  transform: scale(1);
}

.related-team-member .related-team .single-team .team-image:hover .single-team .team-details {
  display: none;
}

.related-team-member .related-team .single-team .team-image .overley {
  background: #ffffffe6;
  width: 100%;
  height: 100%;
  transition: all .35s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(0);
}

.related-team-member .related-team .single-team .team-image .overley .team-info {
  text-align: center;
  padding: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.related-team-member .related-team .single-team .team-image .overley .team-info h3 {
  margin-bottom: 10px;
}

.related-team-member .related-team .single-team .team-image .overley .team-info h3 a {
  color: #000;
  font-size: 24px;
  font-weight: 600;
  transition: all .35s ease-out;
}

.related-team-member .related-team .single-team .team-image .overley .team-info h3 a:hover, .related-team-member .related-team .single-team .team-image .overley .team-info p.designation {
  color: var(--color-accent);
}

.related-team-member .related-team .single-team .team-image .overley .team-info ul {
  text-align: center;
}

.related-team-member .related-team .single-team .team-image .overley .team-info ul li {
  margin: 0 5px;
  display: inline-block;
}

.related-team-member .related-team .single-team .team-image .overley .team-info ul li a {
  color: #333;
  border: 1px solid var(--color-accent);
  width: 45px;
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  line-height: 45px;
  transition: all .35s ease-out;
  display: block;
}

.related-team-member .related-team .single-team .team-image .overley .team-info ul li a:hover {
  color: #fff;
  background: var(--color-accent);
}

.related-team-member .related-team .single-team .team-details {
  text-align: center;
  background: #fff;
  margin: 30px;
}

.related-team-member .related-team .single-team .team-details h3 {
  margin-bottom: 10px;
}

.related-team-member .related-team .single-team .team-details h3 a {
  color: #000;
  font-weight: 700;
  transition: all .35s ease-out;
}

.related-team-member .related-team .single-team .team-details h3 a:hover, .related-team-member .related-team .single-team .team-details p {
  color: var(--color-accent);
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-prev {
  opacity: 1;
  background: #f0efec;
  border-radius: 0%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  top: 35%;
  left: -60px;
  transform: translateY(-45%);
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-prev i {
  color: #000;
  text-align: center;
  font-size: 25px;
  line-height: 40px;
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-prev:hover {
  background: var(--color-accent);
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-prev:hover i {
  color: #fff;
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-next {
  opacity: 1;
  background: #f0efec;
  border-radius: 0%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  position: absolute;
  top: 35%;
  right: -60px;
  transform: translateY(-45%);
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-next i {
  color: #000;
  text-align: center;
  font-size: 25px;
  line-height: 40px;
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-next:hover {
  background: var(--color-accent);
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-next:hover i {
  color: #fff;
}

.home-testing .single-team {
  position: relative;
}

.home-testing .single-team .team-image {
  margin-bottom: 50px;
  position: relative;
}

.home-testing .single-team .team-image:hover .overley {
  transform: scale(1);
}

.home-testing .single-team .team-image h3 {
  display: block;
}

.home-testing .single-team .team-image img {
  width: 100%;
  height: 100%;
}

.home-testing .single-team .team-image .overley {
  background: #ffffffe6;
  width: 100%;
  height: 100%;
  transition: all .35s ease-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  transform: scale(0);
}

.home-testing .single-team .team-image .overley .team-info {
  text-align: center;
  padding: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.home-testing .single-team .team-image .overley .team-info h3 {
  color: #000;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 600;
  transition: all .35s ease-out;
}

.home-testing .single-team .team-image .overley .team-info p.designation {
  color: var(--color-accent);
  margin-bottom: 0;
}

.home-testing .single-team .team-image .overley .team-info ul {
  text-align: center;
}

.home-testing .single-team .team-image .overley .team-info ul li {
  margin: 0 5px;
  display: inline-block;
}

.home-testing .single-team .team-image .overley .team-info ul li a {
  color: #333;
  border: 1px solid var(--color-accent);
  width: 45px;
  height: 45px;
  font-size: 16px;
  font-weight: 600;
  line-height: 45px;
  transition: all .35s ease-out;
  display: block;
}

.home-testing .single-team .team-image .overley .team-info ul li a:hover {
  color: #fff;
  background: var(--color-accent);
}

.product-container {
  padding: 50px 0;
}

.product-container .pull-left .media-object {
  background-size: cover;
  width: 300px;
  height: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 150px;
}

.product-container .media-body h4 {
  color: var(--color-accent);
}

.product-container .media-body p {
  margin-top: 3rem;
}

.line {
  border-top: 1px solid #d3d3d3;
  width: 100%;
  margin: 20px 0;
}

.our-work-solution {
  padding: 50px 0 !important;
}

.solution-page {
  padding: 50px;
}

.solution-page .single-team-content-area h3 {
  color: #000;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 30px;
  font-weight: 700;
}

.solution-page .single-team-content-area .designation p {
  color: var(--color-accent);
  margin: 0;
  font-size: 16px;
  font-weight: 500;
}

.solution-page .single-team-content-area p {
  margin: 30px 0;
  font-size: 15px;
  font-weight: 500;
}

.solution-page .single-team-content-area .team-information {
  float: right;
  margin-top: 10px;
  margin-bottom: 50px;
  padding-top: 20px;
}

.solution-page .single-team-content-area .team-information img {
  background-size: cover;
  width: 200px;
  height: 140px;
  margin-right: 5px;
}

.solution-page .team-feature .single-team-feature {
  position: relative;
}

.solution-page .team-feature .single-team-feature:after {
  content: "";
  background: #ddd;
  width: 1px;
  height: 80px;
  position: absolute;
  top: 0;
  right: 40px;
}

.solution-page .team-feature .single-team-feature .media .pull-left {
  margin-right: 10px;
}

.contact-page-area {
  background: #f8f8f8;
  padding: 20px 0;
}

#map {
  width: 100%;
  height: 300px;
  margin-bottom: 5px;
  padding: 0 30px;
}

.contact-page-area .total-contact-form {
  background: #fff;
  overflow: hidden;
}

.contact-page-area .total-contact-form .contact-form {
  padding: 50px;
}

.contact-page-area .total-contact-form .contact-form h3 {
  color: var(--color-transparent);
  font-size: 24px;
  font-weight: 700;
  position: relative;
}

.contact-page-area .total-contact-form .contact-form h3:after {
  content: "";
  background: var(--color-accent);
  width: 50px;
  height: 3px;
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form {
  margin-left: -20px;
  padding-top: 20px;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset {
  margin-top: 20px;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset input {
  background: #ddd;
  border-radius: 0;
  height: 45px;
  margin-right: 20px;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset input:focus {
  box-shadow: none;
  border: 0;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset textarea {
  background: #ddd;
  border-radius: 0;
  margin-right: 20px;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset textarea:focus {
  box-shadow: none;
  border: 0;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send {
  background: var(--color-accent);
  color: #fff;
  text-transform: uppercase;
  border: 0;
  padding: 15px 45px;
  font-size: 15px;
  font-weight: bold;
  transition: all .35s ease-out;
  display: block;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send:hover {
  background: var(--color-tranparent);
}

.contact-page-area .total-contact-form .contact-information {
  background: var(--color-accent);
  padding: 10px 50px;
  overflow: hidden;
}

.contact-page-area .total-contact-form .contact-information h3 {
  color: #fff;
  margin-bottom: 40px;
  font-size: 24px;
  font-weight: 700;
  position: relative;
}

.contact-page-area .total-contact-form .contact-information h3:after {
  content: "";
  background: var(--color-transparent);
  width: 50px;
  height: 3px;
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
}

.contact-page-area .total-contact-form .contact-information p {
  color: #fff;
  margin: 20px 0;
}

.contact-page-area .total-contact-form .contact-information .more-contact-info {
  margin: 68px 0;
}

.contact-page-area .total-contact-form .contact-information .more-contact-info ul {
  text-align: left;
}

.contact-page-area .total-contact-form .contact-information .more-contact-info ul li {
  color: #fff;
  margin-bottom: 30px;
  display: block;
}

.contact-page-area .total-contact-form .contact-information .more-contact-info ul li i {
  color: var(--color-transparent);
  margin: 0 10px 0 0;
  font-size: 20px;
}

.custom-content-marker {
  width: 25px;
  height: 34px;
  position: relative;
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}
/*# sourceMappingURL=index.4b859a00.css.map */
