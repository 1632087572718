@import url('https://fonts.googleapis.com/css?family=Open+Sans|Raleway:400,500,600,700,800,900');

/*-------------------------------------
  Template Name: SmartIOT HTML5 Template
  Template URI: http://paristhemes.com/
  Description: A Fresh Startup Business Template
  Author: smartiot
  Author URI: http://paristhemes.com/
  Version: 1.0
---------------------------------------  
  CSS INDEX
=======================================
  
  1.Theme Default CSS (body, link color, section etc).
  2.Header Area Start Here
  3.slider area styling
  4.Home Page Service Start Here
  5.Home About Start Here
  6.Home Page Testimonial Start Here
  7.Home Page Portfolio start Here
  8.Home advertise start Here
  9.Home Features Services Start Here
  10.Home Testimonial Start Here
  11.Home Team Memeber Start Here
  12.Counter Up Area Start
  13.Why Smartiot Today Area Start Here
  14.Home Process Start Here
  15.Video Section Start Here
  16.Home Blog Start Here
  17.Footer Area Start Here
  18 Scrollup Section Design Using CSS Code 
  19.Sticky Header Start Here 
  20.Page Header Section breadcumb Start Here
  21.About Page Skill Area Start Here
  22.Single Portfolio Page Start Here
    22.1 Sidebar area start here
  23.Services Page Start Here
    23.1 Pagination Area Start Here
  24.Partner Logo Area Start Here
  25.Blog Page Start Here
  26.Single Blog Page Start Here
  27.Single Team Deatils Page Start Here
    27.1 Related Team Member Start Here
  28.Contact Form Start Here
  29.Error Page Start Here
---------------------------------------*/
/*-------------------------------------
1.  Theme default CSS
-------------------------------------*/
:root {
  --color-accent: #50d7de;
  --color-tranparent: #00000080;
}

html,
body {
  height: 100%;
  font-family: 'Raleway', sans-serif;
  font-size: 14px;
  line-height: 24px;
  vertical-align: baseline;
  color: #646464;
}

a:focus {
  outline: 0px solid;
}

img {
  max-width: 100%;
  height: auto;
}

p {
  margin: 0 0 15px;
}

h2,
h3,
h4 {
  margin: 0 0 25px;
  font-family: 'Raleway', sans-serif;
}

a {
  -webkit-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out;
  text-decoration: none;
}

a:hover {
  color: var(--color-accent);
  text-decoration: none;
  -webkit-transition: all 0.35s ease-out;
  transition: all 0.35s ease-out;
}

a:active,
a:hover {
  outline: 0 none;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.acurate {
  margin: 0;
  padding: 0;
}

.mobile-menu-area {
  display: none;
}

.padding-top {
  padding-top: 30px;
}

.padding-top1 {
  padding-top: 60px;
}

.section-area {
  font-weight: 600;
  font-size: 30px;
  display: inline-block;
  width: 20rem;
  padding: 0.5rem;
  color: white;
  border-radius: 30px;
  text-align: center;
  background-image: linear-gradient(90deg, #073AFFFF -20%, #00FFFFFF 75%);
  margin-bottom: 30px;
}

/* ------------------------------------
2.Header Area Start Here 
---------------------------------------*/
.header-area {
  background: var(--color-tranparent);
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 9999;
}

.header-area .logo-area {
  font-size: 3.5rem;
  /* color: #ffffff; */
  text-align: center;
  padding: 15px 0 10px;
  font-weight: bold;
  color: white;
  margin-top: 20px;

}

.header-area img {
  width: 5rem;
  margin-top: 15px;
}

.header-area .logo-area span {
  font-size: 1.5rem;

  background: -webkit-linear-gradient(#686868, #eae2e2);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

.header-area .main-menu ul {
  text-align: right;
}

.header-area .main-menu ul li {
  display: inline-block;
  padding: 35px 25px;
  position: relative;
  transition: all .3s;
}

.header-area .main-menu ul li:hover ul {
  -webkit-transform: scaleY(1);
  -moz-transform: scaleY(1);
  -o-transform: scaleY(1);
  transform: scaleY(1);
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.header-area .main-menu ul li ul {
  background: #ffffff;
  position: absolute;
  top: 100%;
  transition: all .3s;
  transform-origin: 0 0 0;
  -webkit-transform: scaleY(0);
  -moz-transform: scaleY(0);
  -o-transform: scaleY(0);
  transform: scaleY(0);
  opacity: 0;
  visibility: hidden;
  width: 200px;
  z-index: 9;
  left: 0;
}

.header-area .main-menu ul li ul li {
  display: block;
  padding: 7px 10px;
  text-align: left;
  border-bottom: 1px solid var(--color-accent);
  transition: all .3s;
}

.header-area .main-menu ul li ul li:last-child {
  border-bottom: 0px;
}

.header-area .main-menu ul li ul li a {
  color: #777777;
  text-transform: inherit;
  font-weight: normal;
  font-size: 14px;
}

.header-area .main-menu ul li ul li a:after {
  display: none;
}

.header-area .main-menu ul li ul li a:hover {
  color: var(--color-accent);
}

.header-area .main-menu ul li.active a {
  color: var(--color-accent);
  position: relative;
}

.header-area .main-menu ul li.active a:after {
  position: absolute;
  content: "";
  top: 30px;
  left: 0px;
  right: 0;
  margin: auto;
  width: 25px;
  height: 2px;
  font-style: normal;
  background: var(--color-accent);
}

.header-area .main-menu ul li a {
  display: block;
  text-decoration: none;
  transition: all 0.35s ease-out;
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  position: relative;
}

.header-area .main-menu ul li a:after {
  position: absolute;
  content: "";
  top: 30px;
  left: 0px;
  right: 0;
  margin: auto;
  width: 0px;
  height: 2px;
  font-style: normal;
  background: var(--color-accent);
  transition: all 0.35s ease-out;
}

.header-area .main-menu ul li a:hover {
  color: var(--color-accent);
  position: relative;
}

.header-area .main-menu ul li a:hover:after {
  width: 25px;
}

.header-area .menu-right ul {
  text-align: center;
}

.header-area .menu-right ul li {
  display: inline-block;
  position: relative;
  width: 48px;
}

.header-area .menu-right ul li:last-child:after {
  display: none;
}

.header-area .menu-right ul li:after {
  position: absolute;
  content: "";
  top: 30px;
  right: 0px;
  right: 0;
  margin: auto;
  width: 1px;
  height: 40px;
  font-style: normal;
  background: #ffffff;
  transition: all 0.35s ease-out;
}

.header-area .menu-right ul li span {
  position: absolute;
  bottom: -24px;
  right: 26px;
  z-index: 999;
}

.header-area .menu-right ul li i {
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-size: 20px;
  padding: 37px 0;
  text-decoration: none;
  text-transform: uppercase;
  transition: all 0.35s ease-out;
}

.header-area .menu-right ul li i:hover {
  color: var(--color-accent) !important;
}

.mean-container .mean-nav {
  position: absolute;
  top: 50px;
}

.slide-menu-area {
  background: var(--color-accent);
  width: 0px;
  height: 100vh;
  position: fixed;
  right: -360px;
  top: 0px;
  padding: 130px 30px;
  transition: all 0.5s ease 0s;
  opacity: 0;
  visibility: hidden;
}

.slide-menu-area .close {
  position: absolute;
  left: 0;
  top: 0;
  color: #ffffff;
  opacity: 1;
  padding: 10px;
}

.slide-menu-area .close i {
  color: #ffffff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.35s ease-out;
}

.slide-menu-area .close i:hover {
  color: #222222;
}

.slide-menu-area.highlight {
  right: 0;
  opacity: 1;
  width: 300px;
  visibility: visible;
  z-index: 99999;
}

.slide-menu-area h3 {
  color: #ffffff;
  font-weight: 600;
  position: relative;
}

.slide-menu-area h3:after {
  position: absolute;
  content: "";
  top: 30px;
  left: 0px;
  width: 60px;
  height: 2px;
  font-style: normal;
  background: #ffffff;
}

.slide-menu-area ul {
  text-align: left;
  transition: all 0.35s ease-out;
}

.slide-menu-area ul li {
  display: block;
  position: relative;
  font-size: 14px;
  color: #ffffff;
  margin-right: 30px;
  padding: 10px 0;
}

.slide-menu-area ul li:last-child {
  margin-right: 0;
}

.slide-menu-area ul li span {
  font-weight: 600;
  color: #ffffff;
  padding: 0 1px;
}

.slide-menu-area ul li span i {
  margin-left: 50px;
}

.slide-menu-area ul li a {
  display: block;
  color: #ffffff;
  transition: all 0.35s ease-out;
}

.slide-menu-area ul li a:hover {
  color: #dddddd;
}

.slide-menu-area ul li i {
  color: #ffffff;
  margin-right: 10px;
  font-size: 12px;
}

/*-------------------------------------
 5.Home About Start Here 
 --------------------------------------*/
.home-about-area {
  padding: 50px 0px;
  background: url('/images/about-bg-66c751fb61544.webp') no-repeat;
  background-position: center center;
  background-size: cover;
}

.home-about-area h2 span {
  font-weight: 600;
  font-size: 30px;
  color: var(--color-accent)
}


.home-about-area .about-content .logo {
  width: 20rem;
}

.home-about-area .about-content .about-content-list {
  margin-top: 50px;
}

.home-about-area .about-content .about-content-list .single-list .media .pull-left {
  margin: 20px;
}

.home-about-area .about-content .about-content-list .single-list .media .pull-left span {
  display: block;
  transition: all 0.35s ease-out;

  font-size: 20px;
  color: #ffffff;
  width: 50px;
  height: 50px;
  background: var(--color-accent);
  position: relative;
  text-align: center;
  line-height: 50px;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.home-about-area .about-content .about-content-list .single-list .media .pull-left span:hover {
  transform: rotateY(360deg);
  -webkit-transform: rotateY(360deg);
}

.home-about-area .about-content .about-content-list .single-list .media .pull-left span i {
  -webkit-transform: rotate(120deg);
  -moz-transform: rotate(120deg);
  -o-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.home-about-area .about-content .about-content-list .single-list .media .media-body h3.media-heading {
  padding: 10px 0;
  display: block;
  transition: all 0.35s ease-out;
  font-size: 20px;
  color: #646363;
  font-weight: 500;
}



.home-about-area .about-content .about-content-list .single-list .media .media-body h3:hover {
  color: var(--color-accent);
}

.video-container {
  position: relative;
  overflow: hidden;
  width: 100%;
  max-width: 800px;
  margin: auto;
  margin-top: 5rem;
}

.video-container video {
  width: 100%;
  height: auto;
}


/* ====================================
7.Home Page Portfolio start Here  
=======================================*/
#Container .mix {
  display: none;
}

.our-work-three {
  padding: 30px 0 0px;
  overflow: hidden;
}

.our-work-three .portfolio-page {
  padding: 100px 0 !important;
}

.our-work-three .work-column-menu {
  margin-bottom: 30px;
}

.our-work-three .work-column-menu ul {
  text-align: center;
}

.our-work-three .work-column-menu ul li {
  display: inline-block;
  padding: 5px 20px;
  border: 1px solid var(--color-accent);
  margin-right: 5px;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.35s ease-out;
}

.our-work-three .work-column-menu ul li.active {
  background: var(--color-accent);
  color: #ffffff;
}

.our-work-three .work-column-menu ul li:hover {
  background: var(--color-accent);
  color: #ffffff;
}

.our-work-three .home1-recent-work {
  padding: 20px 0;
}

.our-work-three .home1-recent-work .single-recent-work {
  margin-bottom: 0px;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images {
  position: relative;
  overflow: hidden;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images img {
  object-fit: cover;
  width: 100%;
  height: 17rem;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images:hover .work-details {
  transform: scale(1);
  opacity: 1;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images:hover .link {
  opacity: 1;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details {
  background: var(--color-tranparent) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  transition: all 0.5s ease 0s;
  transform: scale(0);
  width: 100%;
  height: 100%;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  text-align: center;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title h3 {
  margin-bottom: 10px;
  color: #ffffff;
  transition: all 0.35s ease-out;
  font-size: 20px;
  font-weight: 600;
}


.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title h3 a:hover {
  color: #dddddd;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title ul {
  text-align: center;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title ul li {
  display: inline-block;
  margin: 0 5px;
  display: block;
  color: #fff;
  transition: all 0.35s ease-out;
  font-size: 14px;
  font-weight: 600;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .work-details .title ul li a:hover {
  color: #dddddd;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .link {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto;
  opacity: 0;
  padding: 30px;
  transition: all 0.35s ease-out;
  z-index: 999;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .link a {
  font-size: 30px;
  color: #ffffff;
  font-weight: 700;
  transition: all 0.35s ease-out;
  text-decoration: none;
}

.our-work-three .home1-recent-work .single-recent-work .recent-work-images .link a:hover {
  color: var(--color-accent);
}



/* ------------------------------------
17.Footer Area Start Here 
---------------------------------------*/
.footer-top {
  background: rgba(0, 0, 0, 0) url("/images/about-bg_converted.webp") no-repeat scroll center center / cover;
  padding: 50px 0;
  color: #828282;
}

.footer-top .widget .footer-logo {
  margin-bottom: 30px;
}

.footer-top .widget .footer-logo a {
  display: block;
}

.footer-top .widget .social-media ul {
  text-align: left;

}

.footer-top .widget .social-media ul li {
  display: inline-block;
  margin: 2px;
}

.footer-top .widget .social-media ul li a {
  display: block;
  width: 30px;
  height: 30px;
  line-height: 28px;
  color: #dddddd;
  background: #000000;
  border: 1px solid var(--color-accent);
  transition: all 0.35s ease-out;
  text-decoration: none;
  text-align: center !important;
}

.footer-top .widget .social-media ul li a:hover {
  background: var(--color-accent);
  border-radius: 50%;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 1px solid var(--color-accent);
}

.footer-top .widget.single-footer1 p {
  color: #828282;
  /* padding-bottom: 0px; */
}

.footer-top .widget.single-footer2 ul li {
  position: relative;
  margin-left: 20px;
}

.footer-top .widget.single-footer2 ul li:after {
  position: absolute;
  content: "\f054";
  top: 0px;
  left: -25px;
  font-style: normal;
  color: var(--color-accent);
  font-family: fontawesome;
}

.footer-top .widget.single-footer2 ul li a {
  color: #828282;
  display: block;
  margin: 0 0 10px 0;
  font-weight: 500;
  transition: all 0.35s ease-out;
}

.footer-top .widget.single-footer2 ul li a:hover {
  color: var(--color-accent);
}

.footer-top .widget.single-footer4 .form-control {
  height: 45px;
  border-radius: 0;
  background: transparent;
  color: #ededed;
}

.footer-top .widget.single-footer4 .btn-send {
  background: var(--color-accent);
  border-radius: 0;
  color: #ffffff;
  padding: 8px 40px;
  box-shadow: none;
  border: 0;
  transition: all .3s;
  border-radius: 0px;
}

.footer-top .widget.single-footer4 .btn-send:hover {
  background: var(--color-accent);
}

.footer-top .widget .widget-title {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 60px;
  position: relative;
}

.footer-top .widget .widget-title:after {
  position: absolute;
  content: "";
  top: 50px;
  left: 0px;
  width: 60px;
  height: 2px;
  font-style: normal;
  background: var(--color-accent);
  transition: all 0.35s ease-out;
}

.footer-top .widget .single-post {
  color: #828282;
}

.footer-bottom {
  background: #292929;
  padding: 15px 0;
}

.footer-bottom .footer-copy-right p {
  margin: 0;
  color: #969696;
}

.footer-bottom .footer-copy-right p a {
  color: #ffffff;
  font-weight: 700;
  margin: 0 5px;
  transition: all .3s;
}

.footer-bottom .footer-copy-right p a:hover {
  color: var(--color-accent);
}

/*-------------------------------------
18 Scrollup Section Design Using CSS Code 
---------------------------------------*/

/* ------------------------------------
19.Sticky Header Start Here 
---------------------------------------*/

/* ------------------------------------
20.Page Header Section breadcumb Start Here 
---------------------------------------*/
.page-header-area {
  background: rgba(0, 0, 0, 0) url("/images/hk-science-park-16-9.webp") no-repeat scroll center center / cover;
  /* display: flex;
  flex-direction: row; */

  /* height: 25rem; */
  padding: 8rem 0;
  /* padding-bottom: 80px; */
  /* padding: 30px 0; */

}

.page-header-area .title-container {
  position: relative;
  height: fit-content;
  display: inline-block;
  /* top: 30%; */
  top: 3rem;
  /* text-align: left; */
}

.page-header-area .title1 h1 {

  padding: 1rem 1.5rem;
  text-align: center;
  width: 30rem;
  font-size: 1.5rem;
  color: #fff;
  font-weight: bold;
  background-color: var(--color-tranparent);
  border-radius: 30px;
  background-image: linear-gradient(90deg, #073AFFFF -20%, #50d7de 75%)
}


.page-header-area .botton ul li {
  display: inline-block;
  /* height: 1rem; */

}

.page-header-area .botton ul li.active a {
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  transition: all 0.35s ease-out;
  background: rgba(51, 206, 214, 0.832);
  border: 1px solid var(--color-tranparent);
  font-weight: 700;
  text-transform: uppercase;
}

.page-header-area .botton ul li a {
  display: block;
  padding: 10px 20px;
  color: #ffffff;
  background-color: var(--color-tranparent);
  border: 1px solid var(--color-accent);
  transition: all 0.35s ease-out;
  font-weight: 700;
  text-transform: uppercase;
}

.page-header-area .botton ul li a:hover {
  background: var(--color-accent);
}

.page-header-area .botton ul li.active a:hover {
  background: var(--color-tranparent);
}



.page-header-area ul li:last-child {

  margin-top: 2px;
}

/* ----------------------------------
21.About Page Skill Area Start Here 
-------------------------------------*/

/* ------------------------------------
23.Services Page Start Here 
---------------------------------------*/
.services-page {
  /* background: rgba(0, 0, 0, 0) url("images/bg.jpg") no-repeat scroll center center / cover ; */
  padding: 50px 0;
  background: #f8f8f8;
}


.services-page .single-service {
  height: 30rem;
  padding: 30px;
  background: #ffffff;
}

.services-page .single-service .service-image {
  margin-bottom: 30px;
  transition: all 0.35s ease-out;
}

.services-page .single-service .service-image img {

  width: 100%;
  height: 220px;
  object-fit: cover;
}

.services-page .single-service .service-image img:hover {
  opacity: .9;
}

.services-page .single-service h3 {
  margin-bottom: 10px;
}

.services-page .single-service h3 a {
  color: #000000;
  transition: all 0.35s ease-out;
  font-weight: 600;
  font-size: 20px;
}

.services-page .single-service h3 a:hover {
  color: var(--color-accent);
}

.services-page .single-service p {
  font-size: 1.2rem;
}

.services-page .single-service .read-more {
  position: relative;
}

.services-page .single-service .read-more a {
  position: absolute;
  right: -30px;
  bottom: -30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: var(--color-accent);
  transition: all 0.35s ease-out;
}

.services-page .single-service .read-more a:hover {
  background: var(--color-accent);
}

.services-page .single-service .read-more a i {
  color: #ffffff;
}

/*--------------------------------------
  23.1 Pagination Area Start Here
  -------------------------------------*/
.pagination-area {
  padding-top: 60px;
}

.pagination-area ul {
  text-align: left;
}

.pagination-area ul li {
  display: inline-block;
}

.pagination-area ul li.active a {
  background: var(--color-accent);
  color: #ffffff;
}

.pagination-area ul li a {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 45px;
  background: transparent;
  border: 1px solid var(--color-accent);
  color: #ffffff;
  font-size: 20px;
  text-decoration: none;
  transition: all 0.35s ease-out;
  text-align: center;
  background: #555555;
}

.pagination-area ul li a:hover {
  background: var(--color-accent);
  color: #ffffff;
}


/* ------------------------------------
27.1 Related Team Member Start Here 
---------------------------------------*/
.related-team-member {
  border-top: 1px solid #dddddd;
  padding: 50px 20px;
}

.related-team-member .related-team .single-team {
  position: relative;
  margin: 0 15px;
}

.related-team-member .related-team .single-team:hover .overlay {
  transform: scale(1);
}

.related-team-member .related-team .single-team .team-image {

  position: relative;

  padding: 0 20px;
  height: 400px;


}

.related-team-member .related-team .single-team .team-image:hover .overley {
  transform: scale(1);
}

.related-team-member .related-team .single-team .team-image:hover .single-team .team-details {
  display: none;
}



.related-team-member .related-team .single-team .team-image .overley {
  background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  transition: all 0.35s ease-out;
  transform: scale(0);
  width: 100%;
  height: 100%;
}

.related-team-member .related-team .single-team .team-image .overley .team-info {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  text-align: center;
  padding: 30px;
}

.related-team-member .related-team .single-team .team-image .overley .team-info h3 {
  margin-bottom: 10px;
}

.related-team-member .related-team .single-team .team-image .overley .team-info h3 a {
  color: #000000;
  transition: all 0.35s ease-out;
  font-size: 24px;
  font-weight: 600;
}

.related-team-member .related-team .single-team .team-image .overley .team-info h3 a:hover {
  color: var(--color-accent);
}

.related-team-member .related-team .single-team .team-image .overley .team-info p.designation {
  color: var(--color-accent);
}

.related-team-member .related-team .single-team .team-image .overley .team-info ul {
  text-align: center;
}

.related-team-member .related-team .single-team .team-image .overley .team-info ul li {
  display: inline-block;
  margin: 0 5px;
}

.related-team-member .related-team .single-team .team-image .overley .team-info ul li a {
  display: block;
  color: #333333;
  transition: all 0.35s ease-out;
  font-size: 16px;
  font-weight: 600;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 1px solid var(--color-accent);
}

.related-team-member .related-team .single-team .team-image .overley .team-info ul li a:hover {
  color: #ffffff;
  background: var(--color-accent);
}

.related-team-member .related-team .single-team .team-details {
  text-align: center;
  background: #ffffff;
  margin: 30px;
}

.related-team-member .related-team .single-team .team-details h3 {
  margin-bottom: 10px;
}

.related-team-member .related-team .single-team .team-details h3 a {
  transition: all 0.35s ease-out;
  color: #000000;
  font-weight: 700;
}

.related-team-member .related-team .single-team .team-details h3 a:hover {
  color: var(--color-accent);
}

.related-team-member .related-team .single-team .team-details p {
  color: var(--color-accent);
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-prev {
  position: absolute;
  top: 0;
  left: -60px;
  top: 35%;
  transform: translateY(-45%);
  width: 50px;
  height: 50px;
  line-height: 50px;
  -webkit-border-radius: 0%;
  -moz-border-radius: 0%;
  border-radius: 0%;
  opacity: 1;
  background: #f0efec;
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-prev i {
  color: #000000;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-prev:hover {
  background: var(--color-accent);
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-prev:hover i {
  color: #ffffff;
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-next {
  position: absolute;
  right: -60px;
  top: 35%;
  transform: translateY(-45%);
  width: 50px;
  height: 50px;
  line-height: 50px;
  -webkit-border-radius: 0%;
  -moz-border-radius: 0%;
  border-radius: 0%;
  opacity: 1;
  background: #f0efec;
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-next i {
  color: #000000;
  font-size: 25px;
  line-height: 40px;
  text-align: center;
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-next:hover {
  background: var(--color-accent);
}

.related-team-member .related-team .owl-controls .owl-buttons .owl-next:hover i {
  color: #ffffff;
}

/* ------------------------------------
11.Home Team Memeber Start Here 
---------------------------------------*/


.home-testing .single-team {
  position: relative;

}

.home-testing .single-team .team-image {
  position: relative;
  margin-bottom: 50px;
}

.home-testing .single-team .team-image:hover .overley {
  transform: scale(1);
}

.home-testing .single-team .team-image h3 {
  display: block;
}

.home-testing .single-team .team-image img {
  width: 100%;
  height: 100%;


  /* padding: 0 20px; */
  /* objec-fit: contain; */

}

.home-testing .single-team .team-image .overley {
  background: rgba(255, 255, 255, 0.9) none repeat scroll 0 0;
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  transition: all 0.35s ease-out;
  transform: scale(0);
  width: 100%;
  height: 100%;
}

.home-testing .single-team .team-image .overley .team-info {
  top: 50%;
  position: relative;
  transform: translateY(-50%);
  text-align: center;
  padding: 30px;
}

.home-testing .single-team .team-image .overley .team-info h3 {
  margin-bottom: 10px;
  color: #000000;
  transition: all 0.35s ease-out;
  font-size: 24px;
  font-weight: 600;
  color: black;
}




.home-testing .single-team .team-image .overley .team-info p.designation {
  color: var(--color-accent);
  margin-bottom: 0;
}

.home-testing .single-team .team-image .overley .team-info ul {
  text-align: center;
}

.home-testing .single-team .team-image .overley .team-info ul li {
  display: inline-block;
  margin: 0 5px;
}

.home-testing .single-team .team-image .overley .team-info ul li a {
  display: block;
  color: #333333;
  transition: all 0.35s ease-out;
  font-size: 16px;
  font-weight: 600;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border: 1px solid var(--color-accent);
}

.home-testing .single-team .team-image .overley .team-info ul li a:hover {
  color: #ffffff;
  background: var(--color-accent);
}



.product-container {
  padding: 50px 0;

}

.product-container .pull-left .media-object {
  width: 300px;
  height: 100%;
  background-size: cover;
  margin-right: 150px;
  margin-bottom: 10px;
  margin-top: 10px;
}


.product-container .media-body h4 {
  color: var(--color-accent);

}

.product-container .media-body p {
  margin-top: 3rem;
}

.line {
  border-top: 1px solid lightgray;
  /* 淺灰色的線 */
  width: 100%;
  /* 線的寬度 */
  margin: 20px 0;
  /* 上下邊距 */
}


.our-work-solution {
  padding: 50px 0 !important;
}


.solution-page {
  padding: 50px;

}

.solution-page .single-team-content-area h3 {
  font-weight: 700;
  color: #000000;
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.solution-page .single-team-content-area .designation p {
  color: var(--color-accent);
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

.solution-page .single-team-content-area p {
  margin: 30px 0;
  font-size: 15px;
  font-weight: 500;
}

.solution-page .single-team-content-area .team-information {
  padding-top: 20px;
  float: right;
  margin-bottom: 50px;
  margin-top: 10px;
}

.solution-page .single-team-content-area .team-information img {
  width: 200px;
  height: 140px;
  margin-right: 5px;
  background-size: cover;
}


.solution-page .team-feature .single-team-feature {
  position: relative;
}

.solution-page .team-feature .single-team-feature:after {
  position: absolute;
  right: 40px;
  top: 0;
  width: 1px;
  height: 80px;
  background: #dddddd;
  content: "";
}

.solution-page .team-feature .single-team-feature .media .pull-left {
  margin-right: 10px;
}



/* ------------------------------------
28.Contact Form Start Here 
---------------------------------------*/
.contact-page-area {
  background: #f8f8f8;
  padding: 20px 0;
}

.contact-page-area {
  background: #f8f8f8;
  padding: 20px 0;
}

#map {
  padding: 0px 30px;
  width: 100%;
  height: 300px;
  margin-bottom: 5px;
}

.contact-page-area .total-contact-form {
  background: #ffffff;
  overflow: hidden;
}

.contact-page-area .total-contact-form .contact-form {
  padding: 50px
}

.contact-page-area .total-contact-form .contact-form h3 {
  font-weight: 700;
  font-size: 24px;
  color: var(--color-transparent);
  position: relative;
}

.contact-page-area .total-contact-form .contact-form h3:after {
  position: absolute;
  content: "";
  left: 0;
  top: 40px;
  display: block;
  background: var(--color-accent);

  width: 50px;
  height: 3px;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form {
  margin-left: -20px;
  padding-top: 20px;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset {
  margin-top: 20px;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset input {
  background: #dddddd none repeat scroll 0 0;
  border-radius: 0;
  height: 45px;
  margin-right: 20px;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset input:focus {
  border: 0px;
  box-shadow: none;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset textarea {
  background: #dddddd none repeat scroll 0 0;
  border-radius: 0;
  margin-right: 20px;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset textarea:focus {
  border: 0px;
  box-shadow: none;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send {
  background: var(--color-accent);
  border: 0 none;
  color: #ffffff;
  display: block;
  font-size: 15px;
  font-weight: bold;
  padding: 15px 45px;
  text-transform: uppercase;
  transition: all 0.35s ease-out;
}

.contact-page-area .total-contact-form .contact-form .main-contact-form fieldset .btn-send:hover {
  background: var(--color-tranparent);
}

.contact-page-area .total-contact-form .contact-information {
  background: var(--color-accent);
  overflow: hidden;
  padding: 10px 50px;
}

.contact-page-area .total-contact-form .contact-information h3 {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  position: relative;
  margin-bottom: 40px;
}

.contact-page-area .total-contact-form .contact-information h3:after {
  position: absolute;
  content: "";
  left: 0;
  top: 40px;
  display: block;
  background: var(--color-transparent);
  width: 50px;
  height: 3px;
}

.contact-page-area .total-contact-form .contact-information p {
  margin: 20px 0;
  color: #ffffff;
}

.contact-page-area .total-contact-form .contact-information .more-contact-info {
  margin: 68px 0;
}

.contact-page-area .total-contact-form .contact-information .more-contact-info ul {
  text-align: left;
}

.contact-page-area .total-contact-form .contact-information .more-contact-info ul li {
  display: block;
  margin-bottom: 30px;
  color: #ffffff;
}

.contact-page-area .total-contact-form .contact-information .more-contact-info ul li i {
  color: var(--color-transparent);
  margin: 0 10px 0 0px;
  font-size: 20px;
}



.custom-content-marker {
  position: relative;
  width: 25px;
  height: 34px;

}


.custom-content-marker img {
  width: 100%;
  height: 100%;
}